import { useEffect } from 'react';

const Index = () => {
  useEffect(() => {
    const storeUrl = process.env.NEXT_SWELL_PUBLIC_STORE_URL;
    if (storeUrl) {
      window.location.href = storeUrl;
    }
  }, []);

  return (
    <div className="flex min-h-screen min-w-full items-center justify-center text-xl font-bold text-brand-600">
      Redirecting...
    </div>
  );
};

export default Index;
